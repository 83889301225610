import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "./style.css";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Patrocinadores from "../patrocinio/Patrocinadores";
import { Modal } from "@mui/material";
const admin_service = require("../../helpers/admin_service");

const Inicio = () => {
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [patrocinadores, setPatrocinadores] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    bgcolor: "transparent",

    p: 4,
  };
  useEffect(() => {
    admin_service
      .getData("/evento/view-by-hash/" + process.env.REACT_APP_EVT)
      .then((response_evt) => {
        admin_service
          .getData("/patrocinador/view-by-evento/" + process.env.REACT_APP_EVT)
          .then((response_patrocinador) => {
            setEvento(response_evt.data.response_database.result[0]);
            setPatrocinadores(response_patrocinador.data.response.result);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Box paddingTop={10}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={4} alignItems="center">
        <Stack spacing={5} alignItems="center" justifyContent="center">
          <Typography variant="h4" style={{ textAlign: "center" }}>
            <EventIcon /> &nbsp;30 de Noviembre y 1 de Diciembre
          </Typography>
          <Button
            style={{
              backgroundColor: "#a4241c",
              color: "white",
              border: "1px solid transparent",
            }}
            variant="outlined"
            startIcon={<DriveFileRenameOutlineIcon />}
            onClick={() => {
              window.open(
                "https://ebg.edu.gt/inscripcion/c7ebe6ca0b4b846fc664f47a1b524aa6c157ee1e518843dc12d4b68d9838b6ae",
                "_blank"
              );
            }}
          >
            ¡Participa en nuestras rifas!
          </Button>
        </Stack>
        <Stack alignItems="center" justifyContent="center" spacing={5}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: "justify" }}
          >
            <Grid xs={12} md={12} lg={12} p={3}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/0a7e3c51-7271-4a42-94d6-a90dc11ab0ea.docx"
                alt="CINTILLO"
                style={{ width: "100%" }}
                loading="lazy"
                onClick={() => {
                  window.open("https://irtra.org.gt/parques/?pid=59", "_blank");
                }}
              />
            </Grid>{" "}
            <Grid xs={12} md={12} lg={12} p={3} textAlign="center">
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/a35b5aed-8da5-43b3-b4f2-8479eea63160.docx"
                alt="MAPA"
                style={{ width: "50%" }}
                loading="lazy"
              />
            </Grid>
            <Grid xs={12} md={12} lg={12} p={3}>
              {evento.planos && (
                <img
                  src={evento.planos}
                  alt={evento.hash}
                  style={{ width: "100%" }}
                  loading="lazy"
                  onClick={handleOpen}
                />
              )}
            </Grid>
            <Grid xs={12} md={12} lg={12} p={3}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/3edebd82-4e37-4a67-8126-af8b95824700.jpeg"
                style={{ width: "100%" }}
                loading="lazy"
              />
            </Grid>
            <Grid xs={12} md={6} lg={6} p={3}>
              <Typography variant="h4">
                ¿Qué es la Feria Financiera en Familia?
              </Typography>
              <Typography variant="p">
                <br />
                La Asociación Bancaria de Guatemala -ABG-, a través de sus
                iniciativas educativas, se dedica a promover la educación
                financiera desde temprana edad, fomentando el aprendizaje a
                través del juego y la diversión. En esta 10ma Feria Financiera
                en Familia, titulada "Aprende Jugando", buscamos enseñar a los
                niños los principios fundamentales de la gestión financiera,
                permitiéndoles explorar conceptos clave de una manera lúdica y
                accesible.
                <br />
                <br />
                Este evento es una oportunidad única para que los más pequeños
                de la casa aprendan sobre el ahorro, la planificación
                financiera, y la toma de decisiones económicas en un entorno
                dinámico y entretenido. Habrá una serie de juegos interactivos,
                talleres creativos y actividades diseñadas específicamente para
                ellos, donde podrán aprender mientras se divierten.
                <br />
                <br />
                Invitamos a todas las familias a ser parte de esta experiencia
                educativa, que no solo busca educar, sino también unir a las
                familias en torno a la importancia de la educación financiera.
                ¡No te pierdas la 10ma Feria Financiera en Familia - Aprende
                Jugando, y ven a disfrutar de un día lleno de aprendizaje y
                diversión!
                <br />
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <Typography variant="h4">PARQUE IRTRA MUNDO PETAPA</Typography>
              <Typography variant="h5">ÁREA SOCIAL</Typography>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4580.486477892498!2d-90.55061872425901!3d14.571368877790434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a137a6411bd9%3A0x1e4ee43df6ed5d9e!2sIRTRA%20Mundo%20Petapa!5e1!3m2!1ses!2sgt!4v1723584388513!5m2!1ses!2sgt"
                width="75%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/13f69bc5-5a00-47c8-9839-d72b29147207.webp"
                style={{ width: "60%", loading: "lazy" }}
              />
            </Grid>
          </Grid>
          <ImageList cols={5}>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/38055a0b-5aba-4051-b88c-1c9eebd6c123.jpeg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`	https://escuela-bancaria.s3.us-east-2.amazonaws.com/bf53cc69-4c6b-43a0-85b0-232f9b9c52f2.webp`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/947bcef2-6578-4eff-ba8f-5ae42551d4e7.webp`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`	https://escuela-bancaria.s3.us-east-2.amazonaws.com/05732c27-0e34-4f0b-a93a-c8c46b63ba78.jpeg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/4d58b237-470b-4a46-8d32-6d83091c6e39.jpeg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
          </ImageList>
        </Stack>
        {patrocinadores.length > 0 && (
          <Patrocinadores
            evt={process.env.REACT_APP_EVT}
            patrocinadores={patrocinadores}
          />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              src={evento.planos}
              alt={evento.hash}
              style={{ width: "100%", objectFit: "contain", height: "100%" }}
              loading="lazy"
            />
          </Box>
        </Modal>
      </Stack>
    </Box>
  );
};

export default Inicio;
